import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import ContactForm from "../components/SharedTailwind/ContactForm";

function ContactPage(props) {
  return (
    <React.Fragment>
      <Helmet title="Conact us - Gatsby Storefront">
        <meta
          name="description"
          content={props.data.site.siteMetadata.description}
        />
      </Helmet>
      <ContactForm
        title="Contact Gatsby Storefront"
        description="Please describe your project, and we will come back to you shortly."
      />
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default ContactPage;
